import { Leaderboard } from "../components/Leaderboard.jsx"
import { AlertBar } from "../components/AlertBar.jsx"
import { Container } from "react-bootstrap"
import { useContext } from "react"
import { Context } from "../components/GlobalStore.jsx"
import { SearchBar } from "../components/SearchBar.jsx"
import { useEffect } from "react"

export const LeaderboardPage = () => {
    const [state, dispatch] = useContext(Context)
    useEffect(() => {
        dispatch({ type: "RESET_CURRENT_LAUNCHER_ID" })
    }, [])

    return (
        <div>
            <AlertBar />
            <Container>
                <SearchBar />
                <Leaderboard />
            </Container>
        </div>
    )
}
