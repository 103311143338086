import { useQuery } from "react-query"
import { fetchFarmerStatus, testnetEnabled } from "../lib/api"

export default function useCurrentFarmerData(currentLauncherId) {
    return useQuery(
        ["currentfarmerdata", currentLauncherId],
        () => fetchFarmerStatus(currentLauncherId),
        {
            refetchInterval: testnetEnabled ? 5000 : 60000,
            enabled: !!currentLauncherId,
            retry: 10,
        }
    )
}
