import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import React from "react"
import { Header } from "./components/Header.jsx"
import "./custom.scss"
import "font-awesome/css/font-awesome.min.css"
import { Footer } from "./components/Footer.jsx"
import { HomePage } from "./pages/HomePage.jsx"
import { FarmerPage } from "./pages/FarmerPage.jsx"
import { LogInRedirectPage } from "./pages/LogInRedirectPage.jsx"
import { BlockTablePage } from "./pages/BlockTablePage.jsx"
import { LeaderboardPage } from "./pages/LeaderboardPage.jsx"
import { LogOutRedirectPage } from "./pages/LogOutRedirectPage.jsx"
import { NotFoundPage } from "./pages/NotFoundPage.jsx"
import {DonationPage} from "./pages/DonationPage";

function App() {
    return (
        <Router>
            <div className="App bg-dark">
                {/* style={{ height: "100vh" }} */}
                <Header />
                <Switch>
                    <Route path="/farmer/:logInLauncherID/login/:logInToken">
                        <LogInRedirectPage />
                    </Route>
                    <Route path="/farmer/:launcherID">
                        <FarmerPage />
                    </Route>
                    <Route path="/donate">
                        <DonationPage />
                    </Route>
                    <Route path="/recentblocks">
                        <BlockTablePage />
                    </Route>
                    <Route path="/farmers">
                        <LeaderboardPage />
                    </Route>
                    <Route path="/logout?token=:logInToken">
                        <LogOutRedirectPage />
                    </Route>
                    <Route path="/404">
                        <NotFoundPage />
                    </Route>
                    <Route exact path="/">
                        <HomePage />
                    </Route>
                    <Route path="*">
                        <NotFoundPage />
                    </Route>
                </Switch>
                <Footer />
            </div>
        </Router>
    )
}

export default App
