import { useEffect } from "react"
import { useContext } from "react"
import { useParams } from "react-router"
import { Context } from "./GlobalStore"

// Higher Order component to wrap pages which require launcherID

const LauncherIdWrapper = ({ children }) => {
    let { launcherID } = useParams()

    // Global state
    const [state, dispatch] = useContext(Context)

    useEffect(() => {
        if (launcherID) {
            dispatch({ type: "SET_CURRENT_LAUNCHER_ID", payload: launcherID })
        }
    }, [launcherID])

    return <div>{children}</div>
}

export default LauncherIdWrapper
