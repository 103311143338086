import { AlertBar } from "../components/AlertBar.jsx"
import { BlockTable } from "../components/BlockTable.jsx"
import { Main } from "../components/Main.jsx"
import { Jumbo } from "../components/Jumbotron.jsx"
import { Container } from "react-bootstrap"
import { useEffect } from "react"
import { useContext } from "react"
import { Context } from "../components/GlobalStore.jsx"
import { SearchBar } from "../components/SearchBar.jsx"

export const HomePage = () => {
    // Global state
    const [state, dispatch] = useContext(Context)
    useEffect(() => {
        dispatch({ type: "RESET_CURRENT_LAUNCHER_ID" })
    }, [])

    return (
        <div>
            <AlertBar />
            <Jumbo />
            <Container>
                <SearchBar />
                <Main />
                <BlockTable />
            </Container>
        </div>
    )
}
