// Reducers centralize all our methods for changing state into a single function
// Not necessary, but useful

// gets action (action={ type:"POST_BLA", payload:}) (how and what we want to manipulate state) by dispatch
// Workflow
// 1. checks action.type
// 2. update state object
// 3. return state

const Reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_CURRENT_CURRENCY":
            localStorage.setItem("currency", action.payload)
            return {
                ...state,
                currentUserCurrency: action.payload,
            }
        case "SET_CURRENT_LAUNCHER_ID":
            return {
                ...state,
                currentLauncherId: action.payload,
            }
        case "RESET_CURRENT_LAUNCHER_ID":
            return {
                ...state,
                currentLauncherId: null,
            }
        case "SET_LOGIN_TOKEN":
            return {
                ...state,
                logInToken: action.payload,
            }
        case "RESET_LOGIN_TOKEN":
            return {
                ...state,
                logInToken: null,
            }
        case "SET_LOGIN_LAUNCHER_ID":
            return {
                ...state,
                logInLauncherId: action.payload,
            }
        case "RESET_LOGIN_LAUNCHER_ID":
            return {
                ...state,
                logInLauncherId: null,
            }
        case "SET_POOL_CAPACITY":
            return {
                ...state,
                poolCapacity: action.payload,
                lastPoolCapacity: state.poolCapacity,  // store last value
            }
        case "SET_NUMBER_OF_FARMERS":
            return {
                ...state,
                numberOfFarmers: action.payload,
                lastNumberOfFarmers: state.numberOfFarmers,  // store last value
            }
        case "SET_NETWORK_SPACE":
            return {
                ...state,
                networkSpace: action.payload,
                lastNetworkSpace: state.networkSpace,  // store last value
            }
        default:
            return state
    }
}

export default Reducer
