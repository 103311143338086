import { StatBlock } from "./StatBlock"
import { Row, Col } from "react-bootstrap"
import usePoolStatus from "../hooks/usePoolStatus"
import useCurrentFarmerData from "../hooks/useCurrentFarmerData"
import { useParams } from "react-router"
import { Fee } from "./Fee.jsx"
import { useContext, useState, useEffect } from "react";
import { Context } from "./GlobalStore";


export const StatBlockContainer = () => {
    const [state, dispatch] = useContext(Context)
    const { data: poolStatusData, status: poolStatusStatus } = usePoolStatus()
    const { data: currentFarmerData, status: currentFarmerStatus } =
        useCurrentFarmerData(state.currentLauncherId)

    function getFloat(value) {
        return parseFloat(value) ?? 0
    }
    function getUnit(value) {
        return " " + value.split(" ")[1] ?? ""
    }

    useEffect(() => {
        if (poolStatusStatus === "success") {
            dispatch({type: "SET_POOL_CAPACITY", payload: getFloat(poolStatusData.pool_capacity)})
            dispatch({type: "SET_NUMBER_OF_FARMERS", payload: getFloat(poolStatusData.number_of_farmers)})
            dispatch({type: "SET_NETWORK_SPACE", payload: getFloat(poolStatusData.network_space)})
            // console.log(state)
        }
    }, [ poolStatusData, poolStatusStatus ])

    if (poolStatusStatus === "loading") {
        return (
            <Row className="justify-content-center text-white">
                <div>Loading data...</div>
            </Row>
        )
    } else if (poolStatusStatus === "error") {
        return (
            <Row className="justify-content-center text-white">
                <div>Error fetching data...</div>
            </Row>
        )
    }

    return (
        <Row className="justify-content-center mt-4 mb-3">
            <Col xl={10} cs={12}>
                <Row
                    xs={1}
                    sm={2}
                    md={3}
                    lg={4}
                    xl={5}
                    className="justify-content-center"
                >
                    <Col className="mb-4">
                        <StatBlock
                            title={"Pool Capacity"}
                            value={getFloat(poolStatusData.pool_capacity)}
                            last_value={state.lastPoolCapacity}
                            unit={getUnit(poolStatusData.pool_capacity)}
                            countUpProps={{
                                countup: true,
                                decimals: 3,
                            }}
                            tooltip={"The estimated pool capacity calculated by the submitted partials and points of the last 24 hours of all farmers."}
                        />
                    </Col>
                    <Col className="mb-4">
                        <StatBlock
                            title={"Active Farmers"}
                            value={parseInt(poolStatusData.number_of_farmers)}
                            last_value={state.lastNumberOfFarmers}
                            unit={""}
                            countUpProps={{
                                countup: true,
                                decimals: 0,
                            }}
                            tooltip={"The number of currently active farmers."}
                        />
                    </Col>
                    <Col className="mb-4">
                        <StatBlock
                            title={"Est. Time to Win"}
                            value={poolStatusData.time_to_win}
                            unit={""}
                            countUpProps={{ countup: false }}
                            tooltip={"The estimated time to win based on the estimated pool size and the estimated netspace. Due to statistic deviations the time may be more or less. But in average this time will fit."}
                        />
                    </Col>
                    <Col className="mb-4">
                        <Fee />
                    </Col>
                    <Col className="mb-4">
                        <StatBlock
                            title={"Netspace"}
                            value={parseFloat(poolStatusData.network_space)}
                            last_value={state.lastNetworkSpace}
                            unit={getUnit(poolStatusData.network_space)}
                            countUpProps={{ countup: true, decimals: 3 }}
                            tooltip={"The estimated netspace of the Chia Network."}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
