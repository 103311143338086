import { StatBlock } from "./StatBlock"
import { Row, Col } from "react-bootstrap"
import { useContext } from "react"
import { Context } from "./GlobalStore"
import usePoolStatus from "../hooks/usePoolStatus"
import { Fee } from "../components/Fee.jsx"

export const UserStatBlockContainer = ({ data, status, showFee = false }) => {
    // Global state
    const [state, dispatch] = useContext(Context)
    const { data: poolStatusData, status: poolStatusStatus } = usePoolStatus()

    if (status === "loading") {
        return (
            <Row className="justify-content-center text-white mb-4">
                <div>Loading data...</div>
            </Row>
        )
    }
    if (status === "success" && data) {
        return (
            <Row className="justify-content-center mt-4 mb-3">
                <Col xl={10} cs={12}>
                    <Row className="justify-content-center text-white">
                        <h4 className="">Farmer Status</h4>
                    </Row>
                    {data.name && (
                        <Row className="justify-content-center text-white mt-3 mb-3">
                            <h5 className="">{data.name}</h5>
                        </Row>
                    )}
                    <Row
                        xs={1}
                        sm={2}
                        md={3}
                        lg={3}
                        className="justify-content-center"
                    >
                        <Col className="mb-4">
                            <StatBlock
                                title={"Estimated Size"}
                                value={data.capacity}
                                unit={""}
                                countUpProps={{ countup: false }}
                                tooltip={"The estimated farmer size calculated by the submitted partials and points of the last 24 hours. May be a bit delayed when joining the pool."}
                            />
                        </Col>
                        <Col className="mb-4">
                            <StatBlock
                                title={"Balance"}
                                value={data.balance_str}
                                unit={"XCH"}
                                countUpProps={{ countup: false }}
                                showCurrencyConversion={true}
                                tooltip={"The current balance until the next payout."}
                            />
                        </Col>
                        <Col className="mb-4">
                            <StatBlock
                                title={"Points"}
                                value={data.points}
                                unit={""}
                                countUpProps={{ countup: false }}
                                tooltip={"The points submitted since last rewards distribution."}
                            />
                        </Col>
                        <Col className="mb-4">
                            <StatBlock
                                title={"Won Blocks"}
                                value={data.won_blocks}
                                unit={""}
                                countUpProps={{ countup: false }}
                                tooltip={"Total number of won blocks since we're counting them."}
                            />
                        </Col>
                        <Col className="mb-4">
                            <StatBlock
                                title={"Difficulty"}
                                value={data.difficulty}
                                unit={""}
                                countUpProps={{ countup: false }}
                                tooltip={"The current difficulty. It is adjusted so that every farmers sends the same amount of partials."}
                            />
                        </Col>
                        {showFee && (
                            <Col className="mb-4">
                                <Fee />
                            </Col>
                        )}
                        <Col className="mb-4">
                            <StatBlock
                                title={"Estimated Monthly Earnings"}
                                value={data.estimated_monthly_earnings_xch}
                                unit={"XCH"}
                                countUpProps={{ countup: false }}
                                showCurrencyConversion={true}
                                tooltip={"The estimated monthly earnings based on the current estimated size and the current price."}
                            />
                        </Col>
                        <Col className="mb-4">
                            <StatBlock
                                title={"Estimated Yearly Earnings"}
                                value={data.estimated_yearly_earnings_xch}
                                unit={"XCH"}
                                countUpProps={{ countup: false }}
                                showCurrencyConversion={true}
                                tooltip={"The estimated yearly earnings based on the current estimated size and the current price."}
                            />
                        </Col>
                        <Col className="mb-4">
                            <StatBlock
                                title={"Total Wins (before payout)"}
                                value={data.total_wins_str}
                                unit={"XCH"}
                                countUpProps={{ countup: false }}
                                showCurrencyConversion={true}
                                tooltip={"Accumulated wins not taking transaction fees into account."}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
    return null
}
