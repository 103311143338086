import { Container } from "react-bootstrap"
import { StandoutBar } from "./Standoutbar"

export const InfoSection = () => {
    return (
        <Container>
            <h5 className="text-success">Chia Network &trade;</h5>
            <h1 className="display-4">Farming Pool</h1>
            <p className="lead">
                We are operating a Chia Network Farming Pool located in Germany.
                We are strong believers in Chia and are trying to support the
                Chia Project in everything we do. We provide a trustworthy Chia
                farming pool with a very fair split of fees. Let’s farm
                together!
            </p>
            {/* Commented out since offer runs out on Oct. 8 */}
            {/* <StandoutBar /> */}
        </Container>
    )
}
