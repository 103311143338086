import Table from "react-bootstrap/Table"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

export const RejectedPartialsTable = ({ partialRejections }) => {
    if (partialRejections.length) {
        const rejected_partials = partialRejections.map((partial, index) => (
            <tr key={partial.timestamp}>
                <td>{partial.reason}</td>
                <td>{partial.age_humanized}</td>
            </tr>
        ))

        return (
            <Row id="rejected-partials" className="justify-content-center mt-5">
                <Col sm={12} xl={10}>
                    <h4 className="text-center text-white">
                        Rejected Partials
                    </h4>
                    <Table borderless responsive className="text-white">
                        <thead>
                            <tr className="text-success">
                                <th>Reason</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>{rejected_partials}</tbody>
                    </Table>
                </Col>
            </Row>
        )
    }
    return null
}
