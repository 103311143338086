import React, { createContext, useReducer } from "react"
import Reducer from "./Reducer"

// GlobalStore is a Higher order component, which holds the context (the state)

const initialState = {
    currentUserCurrency: localStorage.getItem("currency") || "usd",
    currentLauncherId: null,
    logInToken: null,
    logInLauncherId: null,
    poolCapacity: null,
    lastPoolCapacity: null,
    numberOfFarmers: null,
    lastNumberOfFarmers: null,
    networkSpace: null,
    lastNetworkSpace: null,
}

const GlobalStore = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState)
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
}

export const Context = createContext(initialState)
export default GlobalStore
