import { Modal, Button } from "react-bootstrap"

export const LogInModal = ({ show, handleClose }) => {
    const buttonStyle = {
        backgroundImage: "linear-gradient(to right, #06BD97,#2AD782)",
        // backgroundImage: "linear-gradient(to right, #06BD97,#7228fa)",
    }

    return (
        <Modal show={show} onHide={handleClose} className="border-0">
            <Modal.Header className="bg-dark text-white border-0">
                <Modal.Title>How to log in:</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-dark text-white">
                <ul>
                    <li>
                        You need to create a secure login link in order to log
                        in. You can do this either using the Chia GUI client, or
                        using the command line.
                        <li>
                            Chia GUI client:
                            <ul>
                                <li>
                                    Go to the pool overview, and click on the
                                    three small dots in the top right in the
                                    respective Pool NFT{" "}
                                </li>
                                <li>
                                    Click on <code>View Pool Login Link</code>
                                </li>
                            </ul>
                        </li>
                        <li>
                            Command line:
                            <ul>
                                <li>
                                    Get your launcher id, either via typing{" "}
                                    <code>chia plotnft show</code> in your
                                    terminal, or using the official Chia GUI
                                    client.
                                </li>
                                <li>
                                    Enter{" "}
                                    <code>
                                        chia plotnft get_login_link
                                        --launcher_id &lt;your launcher id&gt;
                                    </code>{" "}
                                    and replace the last part with your launcher
                                    id (without the <code>0x</code> prefix)
                                </li>
                            </ul>
                        </li>
                    </li>
                    <li>
                        Open the generated URL in your browser. Opening the
                        login URL creates a session on the pool server which
                        leads you to letsfarm.io with the session token
                        automatically supplied!
                    </li>
                    <li>
                        Congrats, you're logged in! You can now see
                        farmer-specific information.
                    </li>
                </ul>
            </Modal.Body>
            <Modal.Footer className="bg-dark text-white border-0">
                <Button
                    style={buttonStyle}
                    className="border-0"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
