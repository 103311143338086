import { Modal, Button } from "react-bootstrap"

export const JoinPoolModal = ({ show, handleClose }) => {
    const buttonStyle = {
        backgroundImage: "linear-gradient(to right, #06BD97,#2AD782)",
        // backgroundImage: "linear-gradient(to right, #06BD97,#7228fa)",
    }

    return (
        <Modal show={show} onHide={handleClose} className="border-0">
            <Modal.Header className="bg-dark text-white border-0">
                <Modal.Title>How to join:</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-dark text-white">
                <ul>
                    <li>Open your Chia client</li>
                    <li>
                        Click on <code>Pools &gt; Join a Pool</code>{" "}
                    </li>
                    <li>
                        Choose <code>Connect to pool</code>, and enter{" "}
                        <code>https://pool.letsfarm.io</code>
                    </li>
                    <li>Verify the shown pool details</li>
                    <li>
                        Click <code>Create</code> to create your plot NFT
                        containing the pool URL
                    </li>
                    <li>
                        Hint: Creating or changing a plot NFT costs 1 mojo! Get
                        it{" "}
                        <a
                            href="https://faucet.chia.net/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            here
                        </a>
                        !
                    </li>
                    <li>Wait for the NFT to be created</li>
                </ul>
                <p>
                    If you prefer a visual Guide, take a look at our{" "}
                    <a
                        href="https://www.youtube.com/watch?app=desktop&v=02KPd5UvqSo&feature=youtu.be"
                        target="_blank"
                        rel="noreferrer"
                    >
                        tutorial video
                    </a>
                    .
                </p>
            </Modal.Body>
            <Modal.Footer className="bg-dark text-white border-0">
                <Button
                    style={buttonStyle}
                    className="border-0"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
