import Table from "react-bootstrap/Table"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import usePoolStatus from "../hooks/usePoolStatus"
import {OverlayTrigger, Tooltip} from "react-bootstrap"
import "../css/tooltip.css"


export const BlockTable = () => {
    const { data: poolStatusData, status: poolStatusStatus } = usePoolStatus()

    const blocks = poolStatusData?.recent_blocks?.map((block, index) => (
        <tr key={block.name}>
            <td>
                <a
                    className="text-white"
                    href={`https://www.spacescan.io/xch/block/${block.name}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {block.confirmed_block_index}
                </a>
            </td>
            <td>
                <a
                    className="text-white"
                    href={`https://www.spacescan.io/xch/block/${block.name}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {block.name}
                </a>
            </td>
            <td>
                <OverlayTrigger
                  key={block.confirmed_block_index}
                  placement="top"
                  overlay={
                    <Tooltip className="green-tooltip" id={`tooltip-${block.confirmed_block_index}`}>
                        {new Date(block.timestamp * 1000).toUTCString()}
                    </Tooltip>
                  }
                >
                    <span>{block.age_humanized}</span>
                </OverlayTrigger>
            </td>
        </tr>
    ))

    return (
        <Row id="recent-blocks" className="justify-content-center mt-5">
            {poolStatusStatus === "loading" && <div>Loading data...</div>}
            {poolStatusStatus === "error" && <div>Error fetching data ...</div>}
            {poolStatusStatus === "success" && (
                <Col sm={12} xl={10}>
                    <h4 className="text-center text-white">Recent Blocks</h4>
                    <Table borderless responsive className="text-white">
                        <thead>
                            <tr className="text-success">
                                <th>Number</th>
                                <th>Block</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>{blocks}</tbody>
                    </Table>
                </Col>
            )}
        </Row>
    )
}
