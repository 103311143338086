import { useContext, useEffect } from "react"
import { Redirect } from "react-router"
import { Context } from "../components/GlobalStore.jsx"

export const LogOutRedirectPage = () => {
    const [state, dispatch] = useContext(Context)

    useEffect(() => {
        dispatch({ type: "RESET_LOGIN_LAUNCHER_ID" })
        dispatch({ type: "RESET_LOGIN_TOKEN" })
        dispatch({ type: "RESET_CURRENT_LAUNCHER_ID" })
    }, [])

    return <Redirect to={`/`} />
}
