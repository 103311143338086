import React, { useContext } from "react"
import { Container } from "react-bootstrap"
import { ChartRecentPoints } from "./ChartRecentPoints"
import { RejectedPartialsTable } from "./RejectedPartialsTable"
import { UserStatBlockContainer } from "./UserStatBlockContainer"
import useCurrentFarmerData from "../hooks/useCurrentFarmerData"
import { Context } from "./GlobalStore"
import { Redirect } from "react-router"
import { BlockTable } from "./BlockTable"

export const Farmer = () => {
    // Global state
    const [state, dispatch] = useContext(Context)
    const { data: currentFarmerData, status: currentFarmerStatus } =
        useCurrentFarmerData(state.currentLauncherId)

    const CondBlockTable = () => {
        if (
            state.logInLauncherId &&
            state.currentLauncherId === state.logInLauncherId
        ) {
            return <BlockTable />
        }
        return null
    }

    let showFee = false
    if (
        state.logInLauncherId &&
        state.currentLauncherId === state.logInLauncherId
    ) {
        showFee = true
    }

    if (currentFarmerStatus === "loading") {
        return (
            <Container>
                <div>Loading data...</div>
            </Container>
        )
    } else if (currentFarmerStatus === "success") {
        return (
            <Container>
                <UserStatBlockContainer
                    data={currentFarmerData}
                    status={currentFarmerStatus}
                    showFee={showFee}
                />
                <ChartRecentPoints
                    recentPoints={currentFarmerData.recent_points}
                />
                <RejectedPartialsTable
                    partialRejections={currentFarmerData.partial_rejections}
                />
                <CondBlockTable />
            </Container>
        )
    } else if (currentFarmerStatus === "error") {
        return <Redirect to={`/404`} />
    } else {
        return null
    }
}
