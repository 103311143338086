export const testnetEnabled = false

export const poolurl = testnetEnabled
    ? "https://testpool.letsfarm.io"
    : "https://pool.letsfarm.io"

export const fetchPoolStatus = async () => {
    testnetEnabled && console.log("fetching pool statusdata")
    const url = `${poolurl}/status`
    const res = await fetch(url)
    return res.json()
}

export const fetchFarmerStatus = async (launcherId) => {
    testnetEnabled && console.log(`fetching user data for ${launcherId}`)
    const url = `${poolurl}/farmer/${launcherId}`
    const res = await fetch(url)
    return res.json()
}

export const fetchUserSettings = async (launcherId, token) => {
    testnetEnabled && console.log("fetching user settings data")
    const url = `${poolurl}/farmer/${launcherId}/settings/${token}`
    const res = await fetch(url)
    return res.json()
}

export const updateUserSettings = async ({ launcherId, token, data }) => {
    testnetEnabled && console.log("posting user settings data")
    const url = `${poolurl}/farmer/${launcherId}/settings/${token}`
    const res = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(data),
    })
    return res.json()
}

export const fetchFarmers = async () => {
    testnetEnabled && console.log("fetching farmers")
    const url = `${poolurl}/farmers`
    const res = await fetch(url)
    return res.json()
}

export const searchFarmer = async (query) => {
    testnetEnabled && console.log("searching farmers")
    const url = `${poolurl}/farmer/search?q=${query}`
    const res = await fetch(url)
    return res.json()
}
