import { Container } from "react-bootstrap"
import { Col, Row } from "react-bootstrap"
import logo from "../logo.svg"
import GTO from "../legal/GTO.pdf"
import DPD from "../legal/DPD.pdf"
import IMPRINT from "../legal/IMPRINT.pdf"

export const Footer = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

    return (
        <Container className="mt-5">
            <Row>
                <Col className="col-6 col-md d-flex justify-content-center align-items-center">
                    <div>
                        <h5 className="text-muted">Donate</h5>
                        <ul className="list-unstyled text-muted">
                            <li>
                                <a href="https://www.paypal.com/donate/?hosted_button_id=GWXP8M7ST9WL8" target="_blank">
                                    PayPal
                                </a>
                            </li>
                        </ul>
                        <h5 className="text-muted">Contact</h5>
                        <ul className="list-unstyled text-muted">
                            <li>
                                E-Mail:{" "}
                                <a href="mailto:hello@letsfarm.io">
                                    hello@letsfarm.io
                                </a>
                            </li>
                            <li>
                                Twitter:{" "}
                                <a
                                    href="https://twitter.com/letsfarm_io"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    letsfarm_io
                                </a>
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col className="col-6 col-md d-none d-md-flex justify-content-center align-items-center">
                    <div>
                        <img
                            alt=""
                            src={logo}
                            width="80"
                            height="80"
                            className="d-block mx-auto "
                        />
                    </div>
                </Col>
                <Col className="col-6 col-md d-flex justify-content-center align-items-center">
                    <div>
                        <h5 className="text-muted">Legal Information</h5>
                        <ul className="list-unstyled text-muted">
                            <li>
                                <a href={GTO} target="_blank" rel="noreferrer">
                                    Terms of Operation
                                </a>
                            </li>
                            <li>
                                <a href={DPD} target="_blank" rel="noreferrer">
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    href={IMPRINT}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Imprint
                                </a>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Row>
            <Row className="align-items-center mt-5">
                <p className="text-muted text-center w-100">
          Copyright © letsfarm.io {getCurrentYear()}. All rights reserved.
                </p>
            </Row>
        </Container>
    )
}
