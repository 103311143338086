import { useContext, useEffect } from "react"
import { Container, Row } from "react-bootstrap"
import { Context } from "../components/GlobalStore.jsx"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLeaf } from "@fortawesome/free-solid-svg-icons"

export const NotFoundPage = () => {
    const [state, dispatch] = useContext(Context)

    useEffect(() => {
        dispatch({ type: "RESET_CURRENT_LAUNCHER_ID" })
    }, [])

    const buttonStyle = {
        backgroundImage: "linear-gradient(to right, #06BD97,#2AD782)",
    }

    const HomeButton = () => {
        return (
            <p className="text-center">
                <Button
                    style={buttonStyle}
                    size="lg"
                    className="border-0 shadow"
                    href="/"
                >
                    <FontAwesomeIcon className="mr-2" icon={faLeaf} />
                    Return to Homepage
                </Button>
            </p>
        )
    }

    return (
        <Container className="mt-5">
            <Row className="justify-content-center mt-5">
                <h3 className="text-white">404 Not Found</h3>
            </Row>
            <Row className="justify-content-center mt-3">
                <HomeButton />
            </Row>
        </Container>
    )
}
