import { useContext, useState } from "react"
import { Modal, Button, Form, Col, Row } from "react-bootstrap"
import { useMutation } from "react-query"
import { updateUserSettings } from "../lib/api"
import { queryClient } from "../index.jsx"
import { Context } from "./GlobalStore"
import { poolurl } from "../lib/api"

export const SettingsModal = ({
    show,
    handleClose,
    userSettings,
    userSettingsStatus,
}) => {
    const [state, dispatch] = useContext(Context)

    function handleError(data) {
        if (data.email && data.name) {
            setError({
                ...defaultErrorState,
                isError: true,
                name: true,
                email: true,
            })
        } else if (data.email && !data.name) {
            setError({ ...defaultErrorState, isError: true, email: true })
        } else if (data.name && !data.email) {
            setError({ ...defaultErrorState, isError: true, name: true })
        }
    }

    function checkQuery(data) {
        if (data.status === "error") {
            handleError(data)
        } else {
            resetErrorState()
            queryClient.invalidateQueries("userSettingsData")
            handleClose()
        }
    }
    const { mutate } = useMutation(updateUserSettings, {
        mutationKey: [
            "updateUserSettings",
            state.logInLauncherId,
            state.logInToken,
        ],
        onSuccess: (data, variables, context) => {
            checkQuery(data)
        },
    })

    const [notifications, setNotifications] = useState({
        enable_notifications: null,
        enable_notificationsModified: false,
    })
    const [credentials, setCredentials] = useState({
        name: "",
        nameModified: false,
        email: "",
        emailModified: false,
    })

    const defaultErrorState = {
        isError: false,
        email: false,
        name: false,
    }
    const [error, setError] = useState(defaultErrorState)

    const onSwitchChange = (event) => {
        setNotifications({
            ...notifications,
            [event.target.name]: event.target.checked,
            [event.target.name + "Modified"]: true,
        })
    }

    const onInputChange = (event) => {
        setCredentials({
            ...credentials,
            [event.target.name]: event.target.value,
            [event.target.name + "Modified"]: true,
        })
    }

    const resetErrorState = () => {
        setError(defaultErrorState)
    }

    const buttonStyle = {
        backgroundImage: "linear-gradient(to right, #06BD97,#2AD782)",
    }

    let modalContents = ""

    if (userSettingsStatus === "success") {
        modalContents = (
            <Form>
                <Form.Group as={Row} controlId="formLauncherId">
                    <Form.Label column sm="4">
                        Launcher Id
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control
                            plaintext
                            readOnly
                            className="text-white"
                            defaultValue={userSettings.launcher_id}
                        ></Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formPayout">
                    <Form.Label column sm="4">
                        Payout Address
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control
                            plaintext
                            readOnly
                            className="text-white"
                            defaultValue={userSettings.payout_instructions}
                        ></Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formPayout">
                    <Form.Label column sm="4">
                        Transactions
                    </Form.Label>
                    <Col sm="8">
                        <a href={`${poolurl}/farmer/${state.logInLauncherId}/transactions/${state.logInToken}`}>
                            <Button
                            style={buttonStyle}
                            className="border-0">
                                Download Transactions (CSV)
                            </Button>
                        </a>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formName">
                    <Form.Label column sm="4">
                        Name
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control
                            name="name"
                            defaultValue={userSettings.name}
                            onChange={onInputChange}
                            isInvalid={error.name}
                            maxLength="100"
                        />
                        {error.name && (
                            <Form.Control.Feedback type="invalid">
                                The name you supplied is invalid!
                            </Form.Control.Feedback>
                        )}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formEmail">
                    <Form.Label column sm="4">
                        E-Mail
                    </Form.Label>
                    <Col sm="8">
                        <Form.Control
                            name="email"
                            defaultValue={userSettings.email}
                            onChange={onInputChange}
                            isInvalid={error.email}
                            maxLength="100"
                        />
                        {error.email && (
                            <Form.Control.Feedback type="invalid">
                                The e-mail address you supplied is invalid!
                            </Form.Control.Feedback>
                        )}
                    </Col>
                </Form.Group>

                <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Enable notifications"
                    name="enable_notifications"
                    defaultChecked={userSettings.enable_notifications}
                    onChange={onSwitchChange}
                />
            </Form>
        )
    } else if (userSettingsStatus === "loading") {
        modalContents = <div>Loading currentFarmerData</div>
    }

    return (
        <Modal
            show={show}
            onExiting={resetErrorState}
            onHide={handleClose}
            className="border-0"
        >
            <Modal.Header className="bg-dark text-white border-0">
                <Modal.Title>Farmer Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-dark text-white">
                {modalContents}
            </Modal.Body>
            <Modal.Footer className="bg-dark text-white border-0">
                <Button
                    onClick={() =>
                        // build POST contents conditionally and include only modified values
                        mutate({
                            launcherId: state.logInLauncherId,
                            token: state.logInToken,
                            data: {
                                ...(credentials.emailModified && {
                                    email: credentials.email,
                                }),
                                ...(credentials.nameModified && {
                                    name: credentials.name,
                                }),
                                ...(notifications.enable_notificationsModified && {
                                    enable_notifications:
                                        notifications.enable_notifications,
                                }),
                            },
                        })
                    }
                >
                    Apply Changes
                </Button>
                <Button
                    style={buttonStyle}
                    className="border-0"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
