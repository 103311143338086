import { Navbar, Nav } from "react-bootstrap"
import logo from "../logo.svg"
import { Currency } from "./Currency"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import {useContext, useEffect, useState} from "react"
import { LogInModal } from "./LogInModal"
import { SettingsModal } from "./SettingsModal"
import { poolurl } from "../lib/api.js"
import { Link } from "react-router-dom"
import { Context } from "./GlobalStore"
import useUserSettings from "../hooks/useUserSettings"
import { LinkContainer } from "react-router-bootstrap";


export const Header = () => {
    const [logInShow, setLogInShow] = useState(false)
    const [settingsShow, setSettingsShow] = useState(false)

    const [state, dispatch] = useContext(Context)

    const { data: userSettingsData, status: userSettingsStatus } =
        useUserSettings()

    let loggedIn = false
    if (userSettingsStatus === "success") {
        loggedIn = true
    } else if (userSettingsStatus === "error") {
        dispatch({type: "RESET_LOGIN_LAUNCHER_ID"})
        dispatch({type: "RESET_LOGIN_TOKEN"})
        dispatch({type: "RESET_CURRENT_LAUNCHER_ID"})
    }

    // Login via local storage
    useEffect(() => {
        if (localStorage.getItem('launcher-id') && localStorage.getItem('token')) {
            dispatch({ type: "SET_LOGIN_LAUNCHER_ID", payload: localStorage.getItem('launcher-id') })
            dispatch({ type: "SET_LOGIN_TOKEN", payload: localStorage.getItem('token') })
        }
    }, [])

    const handleLogInShow = () => setLogInShow(true)
    const handleLogInClose = () => setLogInShow(false)

    const handleSettingsShow = () => setSettingsShow(true)
    const handleSettingsClose = () => setSettingsShow(false)

    function handleLogout() {
        localStorage.removeItem('launcher-id')
        localStorage.removeItem('token')
        return true
    }

    return (
        <Navbar variant="dark" expand="md" collapseOnSelect>
            <LinkContainer to="/">
                <Navbar.Brand as={Nav.Link} className="lead">
                    <img
                        alt=""
                        src={logo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top mr-2"
                    />
                    letsfarm.io
                </Navbar.Brand>
            </LinkContainer>

            <Nav className="ml-auto"></Nav>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="flex-grow-0">
                <Nav className="ml-auto">
                    <Currency />
                </Nav>
                <Nav className="ml-auto">
                    <Nav.Link
                        className="text-white"
                        href="https://twitter.com/letsfarm_io"
                    >
                        <FontAwesomeIcon
                            className=" text-success"
                            icon={faTwitter}
                        />
                    </Nav.Link>
                    <LinkContainer to="/">
                        <Nav.Link className="text-white">Home</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/recentblocks">
                        <Nav.Link className="text-white">Recent Blocks</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/farmers">
                        <Nav.Link className="text-white">Farmers</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/donate">
                        <Nav.Link className="text-white">Donate</Nav.Link>
                    </LinkContainer>
                    {loggedIn && (
                        <LinkContainer to={`/farmer/${state.logInLauncherId}`}>
                            <Nav.Link className="text-white">
                                Dashboard
                            </Nav.Link>
                        </LinkContainer>
                    )}
                    {loggedIn && (
                        <Nav.Link
                            className="text-white"
                            href=""
                            onClick={handleSettingsShow}
                        >
                            Account{" "}
                        </Nav.Link>
                    )}
                    {loggedIn ? (
                        <Nav.Link
                            className="text-white"
                            href={`${poolurl}/logout?token=${state.logInToken}`}
                            onClick={handleLogout}
                        >
                            Log out
                        </Nav.Link>
                    ) : (
                        <Nav.Link
                            className="text-white"
                            href=""
                            onClick={handleLogInShow}
                        >
                            Log in
                        </Nav.Link>
                    )}
                </Nav>
            </Navbar.Collapse>
            <LogInModal show={logInShow} handleClose={handleLogInClose} />
            <SettingsModal
                show={settingsShow}
                handleClose={handleSettingsClose}
                userSettings={userSettingsData}
                userSettingsStatus={userSettingsStatus}
            />
        </Navbar>
    )
}
