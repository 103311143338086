import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLeaf } from "@fortawesome/free-solid-svg-icons"

const buttonStyle = {
    backgroundImage: "linear-gradient(to right, #06BD97,#2AD782)",
}

export const JoinButton = ({ handleShow }) => {
    return (
        <p className="text-center">
            <Button
                style={buttonStyle}
                size="lg"
                className="border-0 shadow"
                onClick={handleShow}
            >
                <FontAwesomeIcon className="mr-2" icon={faLeaf} />
                Join Pool
            </Button>
        </p>
    )
}
