import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDollarSign, faEuroSign } from "@fortawesome/free-solid-svg-icons"
import { faBtc } from "@fortawesome/free-brands-svg-icons"
import { Nav } from "react-bootstrap"
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons"
import { useContext } from "react"
import { Context } from "./GlobalStore"
import usePoolStatus from "../hooks/usePoolStatus"

export const Currency = () => {
    // GlobalState
    const [state, dispatch] = useContext(Context)
    const { data: poolStatusData, status: poolStatusStatus } = usePoolStatus()

    // Toggle usercurrencies and update global store
    function setNextCurrency() {
        if (state.currentUserCurrency === "usd") {
            dispatch({ type: "UPDATE_CURRENT_CURRENCY", payload: "eur" })
        } else if (state.currentUserCurrency === "eur") {
            dispatch({ type: "UPDATE_CURRENT_CURRENCY", payload: "btc" })
        } else if (state.currentUserCurrency === "btc") {
            dispatch({ type: "UPDATE_CURRENT_CURRENCY", payload: "usd" })
        }
    }

    let currency = ""
    if (poolStatusStatus === "success") {
        if (state.currentUserCurrency === "usd") {
            currency = (
                <span>
                    <FontAwesomeIcon
                        className="mr-1 text-success"
                        icon={faExchangeAlt}
                    />
                    <span> XCH/USD:</span>
                    <FontAwesomeIcon
                        className="ml-3 mr-1 text-success"
                        icon={faDollarSign}
                    />
                    <span>{poolStatusData.price_usd}</span>
                </span>
            )
        } else if (state.currentUserCurrency === "eur") {
            currency = (
                <span>
                    <FontAwesomeIcon
                        className="mr-1 text-success"
                        icon={faExchangeAlt}
                    />
                    <span className="mr-3"> XCH/EUR:</span>
                    <span>{poolStatusData.price_eur}</span>
                    <FontAwesomeIcon
                        className="ml-1 text-success"
                        icon={faEuroSign}
                    />
                </span>
            )
        } else {
            currency = (
                <span>
                    <FontAwesomeIcon
                        className="mr-1 text-success"
                        icon={faExchangeAlt}
                    />
                    <span> XCH/BTC:</span>
                    <FontAwesomeIcon
                        className="ml-3 mr-1 text-success"
                        icon={faBtc}
                    />
                    <span>{poolStatusData.price_btc}</span>
                </span>
            )
        }
        return (
            <Nav.Link
                href="#"
                className="text-white"
                onClick={() => setNextCurrency()}
            >
                {currency}
            </Nav.Link>
        )
    }
    return (
        <Nav.Link href="#" className="text-white d-none d-sm-flex ml-auto mr-2">
            No price data available
        </Nav.Link>
    )
}
