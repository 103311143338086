import { useContext, useState } from "react"
import { Jumbotron, Container } from "react-bootstrap"
import { JoinPoolModal } from "./JoinPoolModal"
import bg from "../img/earth.jpg"
import { JoinButton } from "./JoinButton"
import { StatBlockContainer } from "./StatBlockContainer"
import { Context } from "./GlobalStore"
import { InfoSection } from "./InfoSection"

export const Jumbo = () => {
    // Global State
    const [state, dispatch] = useContext(Context)

    const [show, setShow] = useState(false)

    const bgStyle = {
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${bg})`,
        backgroundPosition: "center top",
        textShadow: "black 0px 0px 10px",
    }

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    return (
        <Jumbotron className="bg-transparent text-white" style={bgStyle}>
            <Container>
                {state.currentLauncherId || state.logInToken ? null : (
                    <InfoSection />
                )}
                <StatBlockContainer />
                {state.currentLauncherId || state.logInToken ? null : (
                    <div>
                        <JoinButton handleShow={handleShow} />
                        <JoinPoolModal show={show} handleClose={handleClose} />
                    </div>
                )}
            </Container>
        </Jumbotron>
    )
}
