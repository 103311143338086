import { useContext, useEffect } from "react"
import { Redirect, useParams } from "react-router"
import { Context } from "../components/GlobalStore.jsx"

export const LogInRedirectPage = () => {
    let { logInLauncherID, logInToken } = useParams()

    const [state, dispatch] = useContext(Context)

    useEffect(() => {
        dispatch({ type: "SET_LOGIN_LAUNCHER_ID", payload: logInLauncherID })
        dispatch({ type: "SET_LOGIN_TOKEN", payload: logInToken })
        localStorage.setItem('launcher-id', logInLauncherID)
        localStorage.setItem('token', logInToken)
    }, [logInLauncherID, logInToken])

    // if params exist,
    if (logInLauncherID && logInToken) {
        return <Redirect to={`/farmer/${logInLauncherID}`} />
    }
    return <Redirect to={`/404`} />
}
