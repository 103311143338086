import Table from "react-bootstrap/Table"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Link } from "react-router-dom"
import useFarmers from "../hooks/useFarmers"

export const Leaderboard = () => {
    const { data: farmers, status, error } = useFarmers()

    if (status === "error") {
        return <span>Error loading data: {error.message}</span>
    }

    if (status === "loading") {
        return <span>Loading farmers ..</span>
    }

    if (status === "success" && farmers.length) {
        const farmers_table = farmers.map((farmer, index) => (
            <tr key={farmer.rank}>
                <td>{farmer.rank}</td>
                <td>
                    <Link to={`/farmer/${farmer.launcher_id}`}>
                        {farmer.name}
                    </Link>
                </td>
                <td>{farmer.capacity}</td>
            </tr>
        ))

        return (
            <Row id="farmers" className="justify-content-center mt-5">
                <Col sm={12} xl={10}>
                    <h4 className="text-center text-white">Top 100 Farmers</h4>
                    <Table borderless responsive className="text-white">
                        <thead>
                            <tr className="text-success">
                                <th>Rank</th>
                                <th>Name</th>
                                <th>Capacity</th>
                            </tr>
                        </thead>
                        <tbody>{farmers_table}</tbody>
                    </Table>
                </Col>
            </Row>
        )
    }
    return null
}
