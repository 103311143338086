import Card from "react-bootstrap/Card"

export const MainCard = ({ title, content }) => {
    return (
        <Card
            bg="transparent"
            border="dark"
            className="h-100 text-justify shadow text-white border-none"
        >
            <Card.Body>
                <Card.Title className="text-info">{title}</Card.Title>
                <Card.Text>{content}</Card.Text>
            </Card.Body>
        </Card>
    )
}
