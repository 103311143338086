import { useContext } from "react"
import { useQuery } from "react-query"
import { Context } from "../components/GlobalStore"
import { fetchUserSettings, testnetEnabled } from "../lib/api"

export default function useUserSettings() {
    const [state, dispatch] = useContext(Context)

    return useQuery(
        ["usersettings", state.logInLauncherId, state.logInToken],
        () => fetchUserSettings(state.logInLauncherId, state.logInToken),
        {
            refetchInterval: testnetEnabled ? 5000 : 100000,
            enabled: !!state.logInToken,
            retry: 10,
        }
    )
}
