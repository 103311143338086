import { StatBlock } from "./StatBlock"
import usePoolStatus from "../hooks/usePoolStatus"
import useCurrentFarmerData from "../hooks/useCurrentFarmerData"
import { useParams } from "react-router"
import {useContext} from "react";
import {Context} from "./GlobalStore";

export const Fee = () => {
    const [state, dispatch] = useContext(Context)
    const { data: poolStatusData, status: poolStatusStatus } = usePoolStatus()
    const { data: currentFarmerData, status: currentFarmerStatus } = useCurrentFarmerData(state.currentLauncherId)

    if ((currentFarmerStatus === "success") && (currentFarmerData.no_fee === true)) {
        // no fee is set
        return (
            <StatBlock
                value={"🏆"}
                title={"Lifetime Zero-Fee"}
                unit={null}
                countUpProps={{countup: false}}
                tooltip={"The fee the pool will take to cover the operating costs."}
            />
        )
    }
    else {
        // default
        return (
            <StatBlock
                value={poolStatusData.fee_str.replace(" %", "")}
                title={"Fee"}
                unit={"%"}
                countUpProps={{ countup: false }}
                tooltip={"The fee the pool will take to cover the operating costs."}
            />
        )
    }
}
