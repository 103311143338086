import { Container } from "react-bootstrap"
import { AlertBar } from "../components/AlertBar.jsx"
import { Farmer } from "../components/Farmer.jsx"
import LauncherIdWrapper from "../components/LauncherIdWrapper.jsx"

export const FarmerPage = () => {
    return (
        <div>
            <LauncherIdWrapper>
                <AlertBar />
                <Container>
                    <Farmer />
                </Container>
            </LauncherIdWrapper>
        </div>
    )
}
