import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { MainCard } from "./MainCard"

export const Main = () => {
    return (
        <Row id="faq">
            <Col sm={12} md={6} className="mb-4">
                <MainCard
                    title="Why do I need a farming pool?"
                    content="One way of earning chia coins (XCH) is to run your own farmer.
                        Unfortunately with the significant increase in netspace recently
                        observed it's getting harder for small farmers to keep up with the
                        big players (whales). Using a farming pool you have the same
                        chance of winning a block while getting paid more often, smoothing
                        out your farming rewards. See the Pools in Chia blog post by Bram
                        Cohen for more details on the pooling protocol."
                />
            </Col>
            <Col sm={12} md={6} className="mb-4">
                <MainCard
                    title="How are block rewards split?"
                    content="The farmer who wins a block receives 1/8 of the block reward,
                        while 7/8 goes to the pool. Most of that part will be distributed
                        to all participants based on each individual netspace
                        contribution. A small fee of that goes to the pool as
                        a way to cover operational costs."
                />
            </Col>
            <Col sm={12} md={6} className="mb-4">
                <MainCard
                    title="Why should I trust you?"
                    content={
                        <div>
                            First of all our Chia farming pool is based on
                            the official pooling protocol which is only
                            available starting with version 1.2.0 of the
                            Chia blockchain software. Chia pools which
                            provided service before relied on questionable
                            practices such as shared keys or custom or
                            closed source software and should not be
                            trusted. What all Chia pools based on the
                            official pooling protocol have in common is that
                            pool participants need to trust the pool
                            provider to pay out the correct amounts. We pay
                            out frequently and additionally we provide detailed
                            information about all pool participants, including
                            past payments and current account balances among
                            other things. All this information is publicly
                            accessible for everyone. Additionally we provide
                            email alerting for important farmer events which
                            can be enabled after logging in. See{" "}
                            <a href="https://www.youtube.com/watch?v=YQNRa5Bszts">
                                this
                            </a>{" "}
                            video for a quick introduction to this process.
                        </div>
                    }
                />
            </Col>
            <Col sm={12} md={6} className="mb-4">
                <MainCard
                    title="How is your pool different from others?"
                    content="We think that trust is one of the most important factors when
                        choosing a Chia farming pool. That's why we did all the paper work to
                        make sure to fulfil all the legal requirements. The letsfarm.io Chia
                        pool is operated by a Germany based company. The Federal Financial
                        Supervisory Authority (BaFin) supervises banks, financial service
                        institutions and insurance undertakings in Germany and also needs
                        to be involved when operating a farming pool in Germany. We made
                        sure that our pool is compliant with all the legal constraints and
                        we encourage all farmers to verify this before joining a pool."
                />
            </Col>
            <Col sm={12} md={6} className="mb-4">
                <MainCard
                    title="Can I join your pool today?"
                    content="Yes, you can! The official pooling protocol was published
                        on July 8th. In order to start pooling you need to install version
                        1.2.0 or higher of the Chia software. Afterwards just follow the
                        instructions shown when clicking the 'JOIN POOL' button above.
                        Let's farm together!"
                />
            </Col>
            <Col sm={12} md={6} className="mb-4">
                <MainCard
                    title="About us"
                    content="We are a competent team from Germany. We have
                        experience in blockchain technologies, functional safety and testing of mission
                        critical systems as well as a strong IT security background, enabling
                        us to provide a legit, reliable and secure Chia farming pool. For any
                        questions or feedback feel free to reach out at hello@letsfarm.io ."
                />
            </Col>
        </Row>
    )
}
