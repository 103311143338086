import {
    Axis,
    AreaSeries,
    Grid,
    XYChart,
    Tooltip,
    buildChartTheme,
} from "@visx/xychart"
import { LinearGradient } from "@visx/gradient"

const customTheme = buildChartTheme({
    // colors
    backgroundColor: "#01010e", // used by Tooltip, Annotation
    // colors: ["#06bd97"], // categorical colors, mapped to series via `dataKey`s
    colors: ["url(#gradientSuccessPrimary)"], // categorical colors, mapped to series via `dataKey`s

    // labels
    svgLabelBig: { fill: "#06bd97", fontSize: "16px" },
    // svgLabelBig: { fill: "#b794f4", fontSize: "14px" },
    svgLabelSmall: {
        fill: "#74a0d0",
        fillOpacity: "100%",
        fontWeight: "normal",
        fontSize: "11px",
    },

    // lines
    // xAxisLineStyles?: LineStyles;
    // yAxisLineStyles?: LineStyles;
    // xTickLineStyles?: LineStyles;
    // yTickLineStyles?: LineStyles;
    // tickLength: 100,

    // grid
    gridColor: "#b794f470",
    // gridColorDark: "white", // used for axis baseline if x/yxAxisLineStyles not set
    // gridStyles?: CSSProperties;
})

export const ChartRecentPoints = ({ recentPoints }) => {
    const accessors = {
        xAccessor: (d) => d[1],
        yAccessor: (d) => d[2],
    }

    return (
        <div>
            <h4 className="text-center text-white">Recent Points</h4>
            <XYChart
                height={400}
                xScale={{ type: "band" }}
                yScale={{ type: "linear" }}
                theme={customTheme}
            >
                <LinearGradient
                    from="#06bd97"
                    to="#74a0d0"
                    id="gradientSuccessPrimary"
                />
                <Axis
                    orientation="bottom"
                    label="Hours ago"
                    tickFormat={(x) => -1 * x + " h"}
                    labelOffset={30}
                    numTicks={8}
                />
                <Axis
                    orientation="right"
                    numTicks={5}
                    label="Recent Points"
                    tickFormat={() => ""}
                    hideAxisLine={true}
                    hideTicks={true}
                    hideZero={true}
                    labelOffset={0}
                />
                <Axis
                    orientation="left"
                    numTicks={5}
                    hideZero={true}
                    // TODO: adapt format to length of values
                    // tickFormat={(x) => x / 1000 + "k"}
                />
                <AreaSeries
                    dataKey="Recent Points"
                    data={recentPoints}
                    {...accessors}
                    fillOpacity={0.4}
                />
                <Grid
                    columns={false}
                    numTicks={4}
                    stroke={"#b794f470"}
                    strokeOpacity={0.7}
                    strokeDasharray="2,2"
                />
                <Tooltip
                    snapTooltipToDatumX
                    snapTooltipToDatumY
                    showVerticalCrosshair
                    showSeriesGlyphs
                    // glyphStyle={}
                    renderTooltip={({ tooltipData, colorScale }) => (
                        <div>
                            <div
                                style={{
                                    color: colorScale(
                                        tooltipData.nearestDatum.key
                                    ),
                                }}
                            >
                                {accessors.yAccessor(
                                    tooltipData.nearestDatum.datum
                                )}{" "}
                                points,
                            </div>
                            {-1 *
                                accessors.xAccessor(
                                    tooltipData.nearestDatum.datum
                                ) +
                                "h ago"}
                        </div>
                    )}
                />
            </XYChart>
        </div>
    )
}
