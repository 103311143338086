import { useState } from "react"
import Alert from "react-bootstrap/Alert"
import usePoolStatus from "../hooks/usePoolStatus"

export const AlertBar = () => {
    const [show, setShow] = useState(true)
    const { data: poolStatusData } = usePoolStatus()

    if (show) {
        if (poolStatusData?.message) {
            return (
                <Alert
                    className="mb-0"
                    dismissible
                    variant="success"
                    onClose={() => setShow(false)}
                >
                    {poolStatusData.message}
                </Alert>
            )
        }
        return null
    }
    return null
}
