import { useQuery } from "react-query"
import { fetchFarmers, testnetEnabled } from "../lib/api"

export default function useFarmers() {
    return useQuery("farmersData", fetchFarmers, {
        refetchInterval: testnetEnabled ? 5000 : 60000,
        enabled: true,
        retry: 10,
    })
}
