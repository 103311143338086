import {Card, OverlayTrigger, Tooltip} from "react-bootstrap"
import "../css/tooltip.css"
import CountUp from "react-countup"
import { useContext } from "react"
import { Context } from "./GlobalStore"
import usePoolStatus from "../hooks/usePoolStatus";

export const StatBlock = ({
    title,
    value,
    last_value,
    unit,
    countUpProps,
    showCurrencyConversion = false,
    tooltip=null
}) => {
    const [state, dispatch] = useContext(Context)
    const { data: poolStatusData, status: poolStatusStatus } = usePoolStatus()

    const cardStyle = {
        maxWidth: "18rem",
    }

    const statStyle = {
        fontSize: "30px",
    }

    let cardText = ""
    let card = ""

    function getCurrencyConversion() {
        function trim(x, y) {
            return Number.parseFloat(x).toFixed(y)
        }

        if (showCurrencyConversion) {
            if (state.currentUserCurrency === "usd") {
                const usd_fiat = trim(poolStatusData.price_usd * value, 2)
                return "$ " + usd_fiat
            } else if (state.currentUserCurrency === "eur") {
                const eur_fiat = trim(poolStatusData.price_eur * value, 2)
                return eur_fiat + " €"
            } else {
                const val_btc = trim(
                    poolStatusData.price_btc * value,
                    6
                )
                return val_btc + " BTC"
            }
        }
        return ""
    }

    const CurrencyLine = () => {
        return (
            <Card.Text className="mt-n2">
                <small className="text-info ">{getCurrencyConversion()}</small>
            </Card.Text>
        )
    }

    if (countUpProps.countup) {
        cardText = (
            <Card.Text
                className="lead text-left text-nowrap text-success mb-0 "
                style={statStyle}
            >
                <CountUp
                    start={last_value}
                    end={value}
                    decimals={countUpProps.decimals}
                    suffix={unit}
                    delay={0.0}
                    duration={1}
                />
            </Card.Text>
        )
    }
    else {
        cardText = (
            <Card.Text
                className="lead text-left text-nowrap text-success mb-0 "
                style={statStyle}
            >
                {value} {unit}
            </Card.Text>
        )
    }

    if (tooltip) {
        return (
            <Card className="bg-transparent mx-auto" style={cardStyle}>
                <Card.Body>
                    <OverlayTrigger
                        key={title}
                        placement="top"
                        overlay={
                            <Tooltip className="green-tooltip" id={`tooltip-${title}`}>
                                {tooltip}
                            </Tooltip>
                        }
                    >
                        <span>
                            <Card.Subtitle className="text-white text-nowrap mb-1">
                                {title}
                            </Card.Subtitle>
                            {cardText}
                            <CurrencyLine/>
                        </span>
                    </OverlayTrigger>

                </Card.Body>
            </Card>
        )
    }
    else {
        return (
            <Card className="bg-transparent mx-auto" style={cardStyle}>
                <Card.Body>
                    <Card.Subtitle className="text-white text-nowrap mb-1">
                        {title}
                    </Card.Subtitle>
                    {cardText}
                    <CurrencyLine/>
                </Card.Body>
            </Card>
        )
    }
}
