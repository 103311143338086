import { AlertBar } from "../components/AlertBar.jsx"
import { BlockTable } from "../components/BlockTable.jsx"
import { Main } from "../components/Main.jsx"
import { Jumbo } from "../components/Jumbotron.jsx"
import { Container } from "react-bootstrap"
import { useEffect } from "react"
import { useContext } from "react"
import { Context } from "../components/GlobalStore.jsx"
import { SearchBar } from "../components/SearchBar.jsx"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {MainCard} from "../components/MainCard";

export const DonationPage = () => {
    // Global state
    const [state, dispatch] = useContext(Context)
    useEffect(() => {
        dispatch({ type: "RESET_CURRENT_LAUNCHER_ID" })
    }, [])

    return (
        <div>
            <AlertBar />
            <Jumbo />
            <Container>
                <Row id="faq">
                    <Col sm={12} md={12} className="mb-4">
                        <MainCard
                            title="Support us with donations!"
                            content={
                                <div>
                                Providing a legit and trustworthy Chia farming pool for the community comes with costs which need to be covered.
                                Consequently we are reliant on user donations besides the small pool fee which doesn’t cover all running costs.
                                <p/>
                                We’d appreciate donations to make sure that we can provide our service in the future 🙏🏻💚:{" "}
                                <a href="https://www.paypal.com/donate/?hosted_button_id=GWXP8M7ST9WL8" target="_blank">
                                    PayPal
                                </a>
                                <p/>Thank you very much!
                                </div>
                            }
                        />
                    </Col>
                </Row>
                <SearchBar />
                <BlockTable />
            </Container>
        </div>
    )
}