import { useEffect, useState } from "react"
import {
    Button,
    Col,
    Form,
    FormControl,
    InputGroup,
    Row,
} from "react-bootstrap"
import { useQuery } from "react-query"
import { useHistory } from "react-router"
import { useDebounce } from "../hooks/useDebounce"
import { searchFarmer } from "../lib/api"
import "../css/searchbar.css"

export const SearchBar = () => {
    const [currentQuery, setCurrentQuery] = useState("")
    const [buttonStatus, setButtonStatus] = useState(true)
    const debouncedQuery = useDebounce(currentQuery, 250)
    const history = useHistory()
    const { data: searchFarmerData, status: searchFarmerStatus } = useQuery(
        ["searchFarmer", debouncedQuery],
        () => searchFarmer(debouncedQuery),
        {
            retry: false,
            enabled: Boolean(currentQuery),
        }
    )

    const buttonStyleSuccess = {
        backgroundImage: "linear-gradient(to right, #06BD97,#2AD782)",
        border: "transparent",
    }
    const buttonStyleError = {
        backgroundImage:
            "linear-gradient(to right, rgb(166, 86, 86), rgb(219, 49, 113))",
        border: "transparent",
    }

    const onChange = (event) => {
        setCurrentQuery(event.target.value)
    }

    const handleButtonColor = () => {
        if (currentQuery === "") {
            setButtonStatus(true)
        } else if (searchFarmerStatus === "success") {
            setButtonStatus(true)
        } else if (searchFarmerStatus === "error") {
            setButtonStatus(false)
        }
    }

    const handleSubmit = (event) => {
        if (searchFarmerStatus === "success") {
            history.push(`/farmer/${searchFarmerData.launcher_id}`)
            // setButtonStatus(true)
        } else if (searchFarmerStatus === "error") {
            alert("Farmer not found.")
            // setButtonStatus(false)
        }
        event.preventDefault()
    }

    const ViewButton = () => {
        return (
            <Button
                style={buttonStatus ? buttonStyleSuccess : buttonStyleError}
                type="button"
                id="button-view"
                onClick={handleSubmit}
            >
                View
            </Button>
        )
    }

    useEffect(() => {
        handleButtonColor()
    }, [currentQuery, searchFarmerStatus])

    return (
        <Row className={"justify-content-center"}>
            <Col xs={12} sm={10} md={8} lg={8}>
                <Form onSubmit={handleSubmit}>
                    {/* <div className="text-white">{searchFarmerStatus}</div> */}
                    <InputGroup className="mb-3">
                        <FormControl
                            type="text"
                            value={currentQuery}
                            placeholder="Enter Name, Alias or Launcher ID"
                            onChange={onChange}
                            aria-label=" Name, Alias or Launcher ID"
                            aria-describedby="button-view"
                        />
                        <InputGroup.Append>
                            <ViewButton />
                        </InputGroup.Append>
                    </InputGroup>
                </Form>
            </Col>
        </Row>
    )
}
